
import { defineComponent, onMounted } from "vue";
import { useLoaderStore } from "@/store/LoaderStore";
import { useAuthStore } from "@/store/AuthStore";
import useAlert from "@/composables/Alert";

export default defineComponent({
  name: "logout",
  components: {},
  setup() {
    /** Variables */
    const loaderStore = useLoaderStore()
    const authStore = useAuthStore();
    const { showTimeAlert } = useAlert();

    /** Methods */
    async function signOut() {
      let statusCode: number | null = null
      try {
        loaderStore.open()
        const response = await authStore.logoutUser()
        statusCode = response.status
      } catch (error) {
        showTimeAlert("Falha ao tentar fazer logout na aplicação", "error")
        // console.error(error)
        loaderStore.close()
      } finally {
        if(statusCode == 204)
          return window.location.href = "/login"
      }
    }

    /** Life cycles */
    onMounted(async() => signOut())

    return {};
  },
});
